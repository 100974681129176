<template>
  <div>
    <b-modal
      id="modal-home"
      size="lg"
      centered
      cancel-title="اغلاق"
      ok-title="طلب المنتج"
      title="طلب المنتج"
      @ok="handleOk"
    >
      <div class="form-group">
        <label for="Products-location">الاسم الكامل</label>

        <input
          type="text"
          name="name"
          v-model="body.name"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="Products-location">الهاتف</label>

        <input
          type="text"
          name="phone"
          v-model="body.phone"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="Products-location">طريقه الدفع</label>

        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="baytype"
            v-model="body.baytype"
            id="baytype1"
            :value="false"
            checked
          />
          <label class="form-check-label" for="baytype1">
            كاش
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="baytype"
            id="baytype2"
            v-model="body.baytype"
            :value="true"
          />
          <label class="form-check-label" for="baytype2">
            اون لاين
          </label>
        </div>
      </div>

      <div class="form-group">
        <label for="Products-status">ملاحظات</label>

        <textarea
          name="note"
          v-model="body.note"
          rows="3"
          class="form-control"
        ></textarea>
      </div>
    </b-modal>

    <section class="page-title text-center">
      <div class="auto-container">
        <div class="content-box">
          <h1>{{ item.name }}</h1>
          <div class="text">
            <nav class="woocommerce-breadcrumb">
              <router-link to="/">الرئيسيه</router-link
              >&nbsp;/&nbsp;<router-link :to="`/category/${item.slug}`">{{
                item.name
              }}</router-link>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <div class="container mb-5 mt-5" style="direction: rtl;">
      <div class="row">
        <div
          class="col-md-4"
          v-for="itm of item.products"
          :key="itm.id"
          :style="width < 580 ? 'padding:10%' : ''"
        >
          <div class="card text-center mt-4">
            <img :src="baseUploadURL + itm.logo" class="card-img-top" />

            <div class="card-body">
              <h5 class="card-title">
                {{ itm.name }}
                <span class="pricex" v-if="itm.newPrice > 0">
                  (
                  {{ itm.newPrice }} ج.م
                  <del>{{ itm.price }} ج.م</del>
                  )
                </span>
                <span class="pricex" v-else>
                  (
                  {{ itm.price }} ج.م )
                </span>
              </h5>
              <div class="cart-btn">
                <router-link :to="'/product/' + itm.slug" class="more"
                  >التفاصيل</router-link
                >
                <a
                  v-b-modal.modal-home
                  @click="body.products_id = itm.id"
                  class="pay"
                >
                  <i class="fa fa-cart-plus"></i>طلب
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

      baseUploadURL: this.$baseUploadURL,
      body: {
        name: null,
        phone: null,
        note: null,
        baytype: false,
        products_id: null,
      },
      item: {
        name: null,
      },
    };
  },
  methods:{
    handleOk() {
      if (this.body.name && this.body.phone) {
        this.$http.post(`order/add`, this.body).then(
          (res) => {
            this.$swal.fire({
              icon: 'success',
              title: 'Success',
              text: `تم طلب المنتج بنجاح وسيتم تحويلك على صفحه الدفع`,
              showConfirmButton: false,
              timer: 1500,
            });
            if (this.body.baytype) {
              setTimeout(() => {
                window.location.replace(res.data);
              }, 1500);
            } else {
              this.body.name = null;
              this.body.phone = null;
              this.body.note = null;
              this.body.baytype = false;
            }
          },
          () => {
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: `حدث خطأ فى الاتصال`,
              showConfirmButton: false,
              timer: 1500,
            });
          },
        );
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          text: `يرجى ملاء الحقول`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  created() {
    const id = this.$route.params.id;
    this.$http.get(`categories/getById/${id}`).then(
      (res) => {
        this.item = res.data;
      },
      (err) => {
        console.log(err);
      }
    );
  },
  watch: {
    "$route.params.id"(id) {
      if (id) {
        this.$http.get(`categories/getById/${id}`).then(
          (res) => {
            this.item = res.data;
          },
          () => {
            this.$bvToast.toast(`حدث خطأ فى الاتصال`, {
              autoHideDelay: 1500,
              variant: "danger",
              appendToast: "true",
              toaster: "b-toaster-bottom-right",
            });
          }
        );
      }
    },
  },
};
</script>
